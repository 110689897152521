<template>
  <vs-tabs>
    <vs-tab icon-pack="feather" icon="icon-package" :label="!isSmallerScreen ? $t('Shipments') : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <shipments />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-package" :label="!isSmallerScreen ? $t('Returns') : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <returns />
      </div>
    </vs-tab>
  </vs-tabs>
</template>

<script>
import Shipments from './Shipments.vue'
import Returns from './Returns.vue'

export default {
  components: {
    Shipments,
    Returns
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  }
}
</script>